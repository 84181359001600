<template>
  <v-container id="error-view" class="fill-height text-center" tag="section">
    <v-row justify="center">
      <v-col cols="auto">
        <h1 class="text-h2 mb-16 font-weight-bold">{{status}}</h1>

        <div class="text-h4 pt-8 pb-10">message: {{message}}</div>

        <!-- <v-btn depressed large to="/">Get me out of here! </v-btn> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { confirmEmail } from '@/services/auth.service'

  export default {
    name: 'EmailConfirm',
    data: () => ({
    status: null,
    message: null,
  }),
  beforeMount() {
      this.message = "Email Confirmed"
      this.token = this.$route.params.token
      this.onToken()
  },
  methods: {
    onToken() {
      confirmEmail(this.token)
        .then(() => {
          this.status = 200
          this.message = "Email Confirmed"
        })
        .catch((error) => {
          this.status = error.response.status
          this.message = error.response.data.message
        })
    }
  },
}
</script>

<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
